
<template>
    <div class="position-relative">
        <slot></slot>
    </div>
</template>

<script>
    import autoComplete from '@tarekraafat/autocomplete.js';

    export default {
        data: function () {
            return {

            };
        },
        props: ["selector"],
        created: function () {
            var vm = this;
            const autoCompleteJS = new autoComplete({
                selector: vm.$props.selector,
                data: {
                    src: async () => {
                        document.querySelector(vm.$props.selector).setAttribute("placeholder", "טוען...");
                        const source = await fetch('/catalog/searchtermautocomplete?term=' + document.querySelector(vm.$props.selector).value);
                        const data = await source.json();
                        document.querySelector(vm.$props.selector).setAttribute("placeholder", autoCompleteJS.placeHolder);
                        return data;
                    },
                    key: ["label"],
                    cache: true,
                },
                query: {
                    manipulate: function (query) {
                        return `${query}`;
                    },
                },
                searchEngine: "loose",
                placeHolder: "",
                maxResults: 5,
                highlight: true,
                debounce: 300,
                threshold: 1,
                resultsList: {
                    render: true,
                    container: (element) => {
                    },
                },
                resultItem: {
                    content: (data, source) => {
                        source.innerHTML = source.innerHTML.replace(")", "))").replace("(", ")").replace("))", "(");
                    },
                    element: "li"
                },
                feedback: (data) => {
                },
                onSelection: (feedback) => {
                    window.location = feedback.selection.value["producturl"];
                },
            });
        },
        methods: {

        },
        computed: {

        }
    }
</script>