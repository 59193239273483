var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "quantity" }, [
        _c("div", { staticClass: "r" }, [
          _c("div", { staticClass: "c" }, [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addQuantity()
                  },
                },
              },
              [_vm._v("+")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.subtractQuantity()
                  },
                },
              },
              [_vm._v("-")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "c" }, [
            _c("input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "",
                min: _vm.basketminimumquantity,
                max: _vm.basketmaximumquantity,
                readonly: "",
              },
              domProps: { value: _vm.quantity },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.quantity,
            expression: "quantity",
          },
        ],
        attrs: { type: "hidden", id: "quantity" },
        domProps: { value: _vm.quantity },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.quantity = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }