﻿import Vue from 'vue';
import basketMixin from '../core/basket'
import jquery from 'jquery';
//import Drift from 'drift-zoom';
import easyzoom from 'easyzoom';

// components
import productSlider from '../components/product-slider'
import productAutocomplete from '../components/product-autocomplete'

Vue.component('product-slider', productSlider);
Vue.component('product-autocomplete', productAutocomplete);

import quantitySelector from '../components/quantity-selector.vue'
Vue.component('quantity-selector', quantitySelector);

var mainVM = new Vue({
    mixins: [basketMixin],
    el: '#mainApp'
});


$(document).ready(function () {
    $(document).on("change", ".spec-filter-checkbox", function (e) {
        $("#filterSpecsBoxForm").submit();
    });
    $(document).on("click", "[data-href]", function (event) {
        location.href = $(this).attr('data-href');
    });

    //change main picture on Product page and init zoom on it
    $(document).on("click", "[data-easyzoom-trigger]", function (e) {
        e.preventDefault();
        var currentMainPicture = $("#product-main-picture").attr("src");
        var clickedImageStandartSizePath = $(this).attr("data-gallery-standart-picture");
        var clickedImageBigSizePath = $(this).attr("data-gallery-big-picture");
        if (currentMainPicture != clickedImageStandartSizePath) {
            $("#product-main-picture").attr("src", clickedImageStandartSizePath);
            //$("#product-main-picture").attr("data-zoom", clickedImageStandartSizePath);
           
            var easyzoomAPI = $('.easyzoom').data('easyZoom');
            easyzoomAPI.swap(clickedImageStandartSizePath, clickedImageBigSizePath);
        }
    });
});

//new Drift(document.querySelector("#product-main-picture"), {
//    containInline: true,
//    inlineContainer: document.querySelector(".product-gallery"),
//    //paneContainer: document.querySelector("#test123")
//});

var $easyzoom = $('.easyzoom').easyZoom();
