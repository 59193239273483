
<template>
    <!--<div v-swiper:mySwiper="swiperOption">
        <div class="swiper-container-wrapper" v-swiper:mySwiper="swiperOption">
            
        </div>
    </div>-->
    <!-- Slider main container -->
    <div class="swiper">
        <!-- Additional required wrapper -->
        <slot></slot>
        <!-- If we need pagination -->
        <!--<div class="swiper-pagination"></div>-->

        <!-- If we need navigation buttons -->
        <!--<div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>-->

        <!-- If we need scrollbar -->
        <!--<div class="swiper-scrollbar"></div>-->
    </div>
</template>

<script>
    import Swiper, { Navigation, Pagination } from 'swiper';

    Swiper.use([Navigation, Pagination]);

    export default {
        //components: {
        //    Swiper,
        //    SwiperSlide
        //},
        //directives: {
        //    swiper: directive
        //},
        props: ["slidesPerView", "spaceBetween"],
        mounted: function () {
            var vm = this;
            const swiper = new Swiper('.swiper', {
                // Optional parameters
                //direction: 'vertical',
                direction: 'horizontal',
                loop: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },


                // And if we need scrollbar
                scrollbar: {
                    el: '.swiper-scrollbar',
                },

                breakpoints: {
                    50: {
                        slidesPerView: 1
                    },
                    435: {

                        slidesPerView: 2,

                    },
                    768: {

                        slidesPerView: 3,
                    },
                    992: {
                        slidesPerView: 4,
                    }

                },


            });
        },
        data() {
            return {
            };
        },
        methods: {

        }

    }


    //import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

    //export default {
    //    components: {
    //        Swiper,
    //        SwiperSlide
    //    },
    //    directives: {
    //        swiper: directive
    //    },
    //    props: ["slidesPerView", "spaceBetween"],
    //    mounted: function () {
    //        var vm = this;

    //    },
    //    data() {
    //        return {
    //            swiperOption: {
    //                // Optional parameters
    //                //direction: 'vertical',
    //                //loop: true,

    //                slidesPerView: this.$props.slidesPerView,
    //                spaceBetween: this.$props.spaceBetween,

    //                // If we need pagination
    //                pagination: {
    //                    el: '.swiper-pagination',
    //                },

    //                // Navigation arrows
    //                navigation: {
    //                    nextEl: '.swiper-button-next',
    //                    prevEl: '.swiper-button-prev',
    //                },

    //                // And if we need scrollbar
    //                scrollbar: {
    //                    el: '.swiper-scrollbar',
    //                },
    //                breakpoints: {
    //                    50: {
    //                        slidesPerView: 1
    //                    },
    //                    435: {

    //                        slidesPerView: 2,

    //                    },
    //                    768: {

    //                        slidesPerView: 3,
    //                    },
    //                    992: {
    //                        slidesPerView: 4,
    //                    }

    //                }
    //            }
    //        };
    //    },
    //    methods: {

    //    }

    //}
</script>