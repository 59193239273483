
<template>
    <div>
        <div class="quantity">
            <div class="r">
                <div class="c">
                    <a href="#" v-on:click.prevent="addQuantity()">+</a>
                    <a href="#" v-on:click.prevent="subtractQuantity()">-</a>
                </div>
                <div class="c"><input type="text" placeholder="" :min="basketminimumquantity" :max="basketmaximumquantity" class="form-control" :value="quantity" readonly /></div>
            </div>
        </div>
        <input type="hidden" id="quantity" v-model="quantity" />
        <slot></slot>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                quantity: 0
            };
        },
        props: ["basketminimumquantity", "basketmaximumquantity", "quantitystep", "measureunit"],
        created: function () {
            this.quantity = this.quantitystep > this.basketminimumquantity ? this.quantitystep : this.basketminimumquantity;
        },
        methods: {
            addQuantity: function () {
                if (this.quantity + this.quantitystep > this.basketmaximumquantity) {
                    this.quantity = this.basketmaximumquantity;
                } else {
                    this.quantity += this.quantitystep;
                }
                this.quantity = this.roundNumber(this.quantity, 12);
            },
            subtractQuantity: function () {
                if (this.quantity - this.quantitystep < this.basketminimumquantity) {
                    this.quantity = this.basketminimumquantity;
                } else {
                    this.quantity -= this.quantitystep;
                }
                this.quantity = this.roundNumber(this.quantity, 12);
            },
            roundNumber: function (number, decimals) {
                var newnumber = new Number(number + '').toFixed(parseInt(decimals));
                return parseFloat(newnumber);
            }
        },
        computed: {
        }
    }
</script>