﻿import 'jquery-validation-unobtrusive';
import { Modal } from 'bootstrap';

export default {
    buttonWait: function (button) {
        if ($(button).length == 0)
            return;

        if ($(button).prop("tagName") === 'INPUT')
            $(button).addClass('preloader');

        else {
            $(button).attr('data-html', $(button).html().trim());
            $(button).css({ width: $(button).outerWidth() });
            $(button).html('<span class="btn-preloader"></span>');
        }
        $(button).attr('waiting', 'true');
    },
    buttonStopWaiting: function (button) {
        if ($(button).length == 0)
            return;

        if ($(button).attr('data-html') !== '')
            $(button).html($(button).attr('data-html'));

        $(button).attr('waiting', 'false');
        $(button).removeClass('preloader');
    },
    buttonIsWaiting: function (button) {
        if ($(button).length == 0)
            return;
        return $(button).attr('waiting') === 'true';
    },
    openModalFromUrl: function (url, name, modalClass, onLoad) {
        //if (name == undefined) {
        //name = url.indexOf("/") > -1 ? url.split("/")[url.split("/").length - 1] : "";
        //}
        $(".drawer").removeClass("open");
        var newModal = this.openModal("<div class=\"preloader\"><div></div></div>", name, modalClass);
        $.ajax({
            type: 'GET',
            url: url,
            xhrFields: {
                withCredentials: true
            },
            dataType: 'html',
            success: function (msg) {
                $(newModal).find(".modal-content").html(msg);
                jQuery.validator.unobtrusive.parse($(newModal).find("form"));
                if (onLoad) {
                    onLoad();
                }
            },
            error: function () {
                $(newModal).find(".modal-body")
                    .html('<div class="alert alert-danger" role="alert">Problem loading modal</div>');
            }
        });
    },
    openModal: function (content, name, modalClass, buttons) {
        var selector = ((name !== undefined && name !== '') ? '#' + name + "-modal" : ".messageModal");
        var messageModal = '<div class="modal fade messageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" id="'
            + ((name !== undefined && name !== '') ? name + "-modal" : '') + '">';
        messageModal += '<div class="modal-dialog modal-dialog-centered ' + modalClass + '" role="document">';
        messageModal += '<div class="modal-content">';
        messageModal += '<div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>';
        messageModal += '<div class="modal-body"></div>';
        messageModal += '<div class="modal-footer"></div>';
        messageModal += '</div></div></div>';

        if ($(selector).length === 0) {
            $('body').append(messageModal);
        }

        if (content.indexOf("modal-body") > -1) {
            $(selector + " .modal-content").html(content);
        } else {
            $(selector + " .modal-body").html(content);
        }

        if (buttons !== undefined) {
            $(selector + " .modal-footer").html("");
            $(selector + " .modal-footer").show();
            var num = 1;
            for (var key in buttons) {
                //console.log(key, buttons[key]);
                //theFooter += "<a href=\"#\">" + key + "</a>";
                var but = $('<button/>',
                    {
                        text: key,
                        class: "btn btn-lg " + (num === 1 ? "btn-primary" : "btn-default"),
                        click: buttons[key]
                    });
                $(selector + " .modal-footer").append(but).end();
                num += 1;
            }
        }

        if (!$(selector).hasClass("show")) {
            var myModal = new Modal($(selector)[0])
            myModal.show();
        }

        //var stoneContTop = parseInt(window.innerHeight / 2 - 150);
        //$('.messageModal .modal-dialog').css({ 'top': stoneContTop + 'px' });

        $(selector).on('hidden.bs.modal', function (e) {
            $(this).remove();
        });

        return $(selector);
    },
    processAjaxResponse: function (xhr) {
        var obj = xhr.responseJSON;
        //var form = $("form");
        var form = $(obj.form);
        if (obj.success) {
            if (obj.redirectUrl !== '') {
                window.location = obj.redirectUrl;
            } else if (obj.ajaxRedirectUrl !== '') {
                pageEvents.openModalFromUrl(obj.ajaxRedirectUrl, "", "");
            } else {
                form.find(".alert").removeClass("alert-danger").removeClass("validation-summary-valid").addClass("alert-success").html(obj.message);
            }
            form.find('.ajax-form-post-hide').addClass('d-none');
            form.find('.ajax-form-post-show').addClass('d-block');
        } else {
            form.find(".error-msg").removeClass("validation-summary-valid").html(obj.message);
        }
    }
};

function processAjaxResponse(xhr) {
    var obj = xhr.responseJSON;
    //var form = $("form");
    var form = $(obj.form);
    if (obj.success) {
        if (obj.redirectUrl !== '') {
            window.location = obj.redirectUrl;
        } else if (obj.ajaxRedirectUrl !== '') {
            pageEvents.openModalFromUrl(obj.ajaxRedirectUrl, "", "");
        } else {
            form.find(".alert").removeClass("alert-danger").removeClass("validation-summary-valid").addClass("alert-success").html(obj.message);
        }
        form.find('.ajax-form-post-hide').addClass('d-none');
        form.find('.ajax-form-post-show').addClass('d-block');
    } else {
        form.find(".error-msg").removeClass("validation-summary-valid").html(obj.message);
    }
}

window.processAjaxResponse = processAjaxResponse;