﻿import axios from "axios";
import core from './core'
import { Offcanvas } from "bootstrap"

export default {
    data() {
        return {
            URLPrefix: "",
            isLoading: false,
            basket: window.basket,
            offCanvas: null
        }
    },
    created: function () {
        var vm = this;
        vm.initPageEvents();
    },
    methods: {
        getBasket: function (shippingId) {
            var vm = this;
            vm.isLoading = true;
            var filterData = {};
            $.ajax({
                url: vm.URLPrefix + '/basket/list?shippingId=' + shippingId + '&shippingCity=' + vm.shippingAddressModel.city,
                data: filterData,
                success: function (basketView) {
                    vm.basket = basketView;
                    vm.isLoading = false;
                }
            });
        },
        saveBasket: function (action, basket, callbackSuccess, callbackError, addToBasketBtn) {
            var vm = this;
            vm.isLoading = true;

            axios({
                method: 'post',
                url: vm.URLPrefix + "/basket/save/?basketaction=" + action,
                data: basket.constructor === Array ? basket : [basket]
            })
                .then(function (response) {
                    vm.isLoading = false;
                    if (response.data.basketWarnings != null && response.data.basketWarnings.length > 0) {
                        alert(basketView.basketWarnings);
                        return;
                    }
                    vm.basket = response.data;
                    vm.afterBasketSave(addToBasketBtn);

                    if (callbackSuccess) {
                        callbackSuccess();
                    }
                    //$(document).trigger("mod:afterSaveBasket", { action: action, basket: basket });
                })
                .catch(err => {
                    vm.isLoading = false;
                    if (callbackError) {
                        callbackError(err);
                    }
                });

            
            //return $.post({
            //    url: vm.URLPrefix + "/basket/save/?basketaction=" + action,
            //    data: JSON.stringify(basket.constructor === Array ? basket : [basket]),
            //    contentType: "application/json; charset=utf-8",
            //    dataType: "json",
            //    success: function (basketView) {
                    
            //    },
            //    error: function (errors) {
            //        vm.isLoading = false;
            //        if (callbackError) {
            //            callbackError(errors);
            //        }
            //    }
            //});
        },
        addToBasketBasketWithAttributes: function (e, productId, quantity, formSelector, callbackSuccess, callbackError) {
            var vm = this;
            var addToBasketBtn = $(e.currentTarget);
            if (core.buttonIsWaiting(addToBasketBtn)) {
                return;
            }
            core.buttonWait(addToBasketBtn);
            return $.post({
                url: vm.URLPrefix + "/basket/addproducttobasket?productid=" + productId + "&quantity=" + quantity,
                data: $(formSelector).serialize(),
                type: "POST",
                success: function (basketView) {
                    vm.isLoading = false;
                    if (basketView.basketWarnings != null && basketView.basketWarnings.length > 0) {
                        alert(basketView.basketWarnings);
                        return;
                    }
                    vm.basket = basketView;
                    vm.afterBasketSave(addToBasketBtn);

                    if (callbackSuccess) {
                        callbackSuccess();
                    }
                },
                error: function (errors) {
                    vm.isLoading = false;
                    if (callbackError) {
                        callbackError(errors);
                    }
                },
                complete: function () {
                    core.buttonStopWaiting(addToBasketBtn);
                }
            });
        },
        afterBasketSave: function (btn) {
            var vm = this;
            if (vm.basket.afterBasketSaveClientAction == "DoNothing") {
            }
            else if (vm.basket.afterBasketSaveClientAction == "OpenSideBasket") {
                if (vm.offCanvas == null) {
                    vm.offCanvas = new Offcanvas('#basketFlyout');
                }
                vm.offCanvas.show();
            }
            else if (vm.basket.afterBasketSaveClientAction == "FlyAnimation") {
                var cart = $('.basket-box');
                var imgtodrag = btn.parent().parent().find("img").eq(0);
                if (imgtodrag) {
                    var imgclone = imgtodrag.clone()
                        .offset({
                            top: imgtodrag.offset().top,
                            left: imgtodrag.offset().left
                        })
                        .css({
                            'opacity': '0.8',
                            'position': 'absolute',
                            'z-index': '999999',
                            'mix-blend-mode': 'multiply'
                        })
                        .appendTo($('body'))
                        .animate({
                            'top': cart.offset().top + 10,
                            'left': cart.offset().left + 10,
                            'width': 75,
                            'height': 75
                        }, 700);

                    imgclone.animate({
                        'width': 0,
                        'height': 0
                    }, function () {
                        $(this).detach()
                    });
                }
            }
            else if (vm.basket.afterBasketSaveClientAction == "RedirectToBasket") {
                window.location = "/basket";
            }
            else if (vm.basket.afterBasketSaveClientAction == "RedirectToCheckout") {
                window.location = "/checkout";
            }
        },
        updateBasketItemQuantity: function (row, quantity) {
            quantity = parseFloat(quantity);
            if (quantity == undefined || isNaN(quantity)) {
                quantity = 0;
            }
            this.saveBasket('updatequantity', { ID: row.id, Quantity: quantity, ProductId: row.productId, ProductVariantId: row.productVariantId });
        },
        updateBasketItemNotes: function () {
            var basketItemId = parseInt($("#relevantBasketItemId").val());
            var notes2Set = $("#setItemNotesInput").val();
            this.basket.items.find(x => x.id == basketItemId).notes = notes2Set;
            this.saveBasket('updatenotes', { ID: basketItemId, Notes: notes2Set });
            $('#setItemNotesModal').modal('hide');

        },
        showsetItemNotesModal: function (basketItem) {
            if (basketItem.notes == undefined || basketItem.notes == null)
                basketItem.notes = "";
            $("#relevantBasketItemId").val(basketItem.id);
            $("#setItemNotesInput").val(basketItem.notes);
            $("#setItemNotesModal").modal("show");
        },
        setBasket: function (basket) {
            this.basket = basket;
        },
        applyDiscountCoupon: function (e) {
            var vm = this;
            var clickedElement = e.target;
            var discountTextbox = $(clickedElement).closest(".discount-input-wrapper").find(".discount-input-trigger")[0];
            var couponCode = $(discountTextbox).val();
            $.ajax({
                url: vm.URLPrefix + '/basket/applydiscountcoupon?discountcouponcode=' + couponCode,
                success: function (basketView) {
                    vm.basket = basketView;
                    vm.isLoading = false;
                }
            });
        },
        removediscountcoupon: function (discountId) {
            var vm = this;
            $.ajax({
                url: vm.URLPrefix + '/basket/removediscountcoupon?discountId=' + discountId,
                success: function (basketView) {
                    vm.basket = basketView;
                    vm.isLoading = false;
                }
            });
        },
        resizedPicture: function (url, width, height, mode, format) {
            var parameters = "";

            if (width > 0)
                parameters += "w_" + width + ",";

            if (height > 0)
                parameters += "h_" + height + ",";

            if (mode != undefined)
                parameters += "mode_" + mode + ",";

            if (format != undefined)
                parameters += "format_" + format + ",";

            if (url == "" || url == undefined)
                url = "404.png";

            var pathPrefix = "";

            return pathPrefix + "/cache/" + parameters.substring(0, parameters.length - 1) + "/" + url;
        },
        initPageEvents: function () {
            var vm = this;
            $(document).on("click", "[basket-add]", function (e) {
                e.preventDefault();
                var quantity = 1;
                var btn = $(this);
                if (core.buttonIsWaiting(btn)) {
                    return;
                }
                core.buttonWait(btn);
                vm.saveBasket("add", {
                    ProductId: parseInt($(this).attr("basket-add")),
                    Quantity: ((!quantity) ? 1 : quantity),
                    ProductVariantId: parseInt($(this).attr("basket-variant-id"))
                }, function () {
                    vm.afterBasketSave(btn);
                    core.buttonStopWaiting(btn);
                }, function () {
                    core.buttonStopWaiting(btn);
                }, btn);
            });

            $(document).on("click", "[data-modal]", function (e) {
                e.preventDefault();
                var url = $(this).data("modal"); //+ "?edit=true"
                var params = "";
                if (url.includes("?")) {
                    params = url.toLowerCase().includes("ismodal") ? "" : "&ismodal=true";
                }
                else {
                    params = "?ismodal=true";
                }
                url += params;
                core.openModalFromUrl(url, $(this).data("modal-name"), $(this).data("modal-class"));
            });
        }
    },
    watch: {
    },
    computed: {
    }
};
